<template>
  <VTransition>
    <section
      v-if="hasCleanings && !hideWarning"
      class="flex flex-col gap-3 p-4 bg-blue-50 dark:bg-blue-950 rounded-lg border border-blue-300 border-solid mb-2"
      aria-labelledby="notification-title"
    >
      <div class="flex flex-wrap items-center gap-3">
        <div class="flex-grow">
          <div class="flex flex-wrap">
            <Icon
              name="bi:stars"
              class="w-6 h-6 text-nt-blue"
            />
            <h5
              id="notification-title"
              class="font-medium text-blue-500"
            >
              Upgrade to unlock all features
            </h5>
          </div>
      
          <p class="text-xs text-slate-500 mt-1">
            The Pro features you used were disabled.
          </p>
        </div>
      
        <div class="flex gap-2 text-xs font-medium">
          <UButton
            v-track.form_cleanings_unlock_all_features
            icon="i-heroicons-check-badge-16-solid"
            @click.prevent="onUpgradeClick"
          >
            {{ form.is_pro ? 'Upgrade plan' : 'Start free trial' }}
          </UButton>
          <UButton
            variant="link"
            @click="isExpanded = !isExpanded"
          >
            {{ isExpanded ? 'Hide details' : 'Show details' }}
          </UButton>
          <UButton
            v-if="specifyFormOwner"
            variant="link"
            @click.prevent="hideWarning = true"
          >
            Dismiss
          </UButton>
        </div>
      </div>

      <div
        v-if="isExpanded"
        class="-mt-2"
      >
        <p
          v-if="specifyFormOwner"
          class="text-sm text-slate-500"
        >
          Only you are seeing this notification, as owner of the form.
        </p>
        <div
          class="text-sm text-slate-500"
          v-html="cleaningContent"
        />        
      </div>
    </section>
  </VTransition>
</template>

<script>
import VTransition from '~/components/global/transitions/VTransition.vue'

export default {
  name: 'FormCleanings',
  components: { VTransition },
  props: {
    form: { type: Object, required: true },
    specifyFormOwner: { type: Boolean, default: false },
    hideable: { type: Boolean, default: false }
  },
  setup() {
    const subscriptionModalStore = useSubscriptionModalStore()
    return {
      subscriptionModalStore
    }
  },
  data() {
    return {
      hideWarning: false,
      isExpanded: false
    }
  },
  computed: {
    hasCleanings () {
      return this.form.cleanings && Object.keys(this.form.cleanings).length > 0
    },
    cleanings () {
      return this.form.cleanings
    },
    cleaningContent () {
      let message = ''
      Object.keys(this.cleanings).forEach((key) => {
        let fieldName = key.charAt(0).toUpperCase() + key.slice(1)
        if (fieldName !== 'Form') {
          fieldName = '"' + fieldName + '" field'
        }
        let fieldInfo = '<br><span class="font-semibold">' + fieldName + '</span><br/><ul class=\'list-disc list-inside\'>'
        this.cleanings[key].forEach((msg) => {
          if (!msg) return
          fieldInfo = fieldInfo + '<li>' + msg + '</li>'
        })
        if (fieldInfo) {
          message = message + fieldInfo + '</ul>'
        }
      })
      return message
    }
  },
  methods: {
    onUpgradeClick () {
      this.subscriptionModalStore.setModalContent('Upgrade to unlock all features for your form', 'Some features are disabled on the published form. Upgrade your plan to unlock these features and much more. Gain full access to all advanced features.')
      this.subscriptionModalStore.openModal()
    }
  }
}
</script>
